import React from 'react'
import { Link } from 'gatsby'
import { Helmet } from "react-helmet";
import { withPrefix } from 'gatsby'


import Layout from '../components/layout'
import Header from '../components/header'

import ImageProfile from '../images/profile-pic.png';

const IndexPage = ({data}) => (
  <Layout>
    <main className="page--about">
      <Helmet>
        <title>About | {data.site.siteMetadata.title}</title>
      </Helmet>
      <section className="slab slab--main">
        <Header siteTitle="Rob DiMarzo" /> 
        <div className="hero__artboard">
            <div className="hero--bg"></div>
            <div className="hero--mask">
              <div className="col"></div>
              <div className="col"></div>
              <div className="col"></div>
              <div className="col"></div>
              <div className="col"></div>
              <div className="col"></div>
              <div className="col"></div>
              <div className="col"></div>
              <div className="col"></div>
              <div className="col"></div>
              <div className="col"></div>
              <div className="col"></div>
              <div className="col"></div>
              <div className="col"></div>
              <div className="col"></div>
              <div className="col"></div>
              <div className="col"></div>
              <div className="col"></div>
              <div className="col"></div>
              <div className="col"></div>
            </div>
          </div>
        <div className="slab__inner">
          <div className="pageblock__image is--half">        
          <img src={ImageProfile}/>
        </div>
          <div className="pageblock__content is--half">
            <p>
                My name is Rob DiMarzo. I’m a UX & Product Designer with a versatile skillset and hands-on experience in the research, design, development, and strategy of digital products. 
            </p>
            <p>
              I am currently a <strong>UI/UX Design Manager</strong> at <a href="https://www.kpmg.com" target="_blank">KPMG</a>, where I lead the UX Research, UX Design, and UI Development of several transactional web apps for asset management and corporate tax.
            </p>
            <p>
              <a href={withPrefix('/robdimarzo-resume.pdf')} className="btn btn--ghost" target="_blank">Download Resume</a>
            </p>
            <p>
              I wear many creative and technical hats within Product Design. Some people have called me a UX generalist, a hybrid designer-developer, or even a unicorn (their words, not mine). If you ask me, I’m just an empathetic and inquisitive designer with many cross-functional proficiencies that reinforce user-centered design through all stages of product development. 
 
            </p> 
            <p>
              Prior to my life in User Experience, I studied Communication Arts and worked professionally across a variety of digital mediums, including web design, video production, motion graphics, print design, photography, and sound design.
            </p>
            <p>
              I live just outside of NYC in Northern New Jersey with my wife <a href="https://www.instagram.com/marya.layth/" target="_blank">Marya Layth</a> and rambunctious cat Pippin. You can catch me on <a href="https://twitter.com/robdimarzo" target="_blank">Twitter</a>, <a href="https://codepen.io/robdimarzo/" target="_blank">CodePen</a>, and <a href="https://www.linkedin.com/in/robdimarzo/" target="_blank">LinkedIn</a> — my DMs are open.
            </p>
            {/* <p>
              This site is build using <a href="https://www.gatsbyjs.org/" target="_blank">Gatsby</a>.
            </p> */}
          </div>

          

        </div>
      </section>
    </main>
  </Layout>
)
export const query = graphql`
  query AboutQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`
export default IndexPage
